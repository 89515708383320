import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { isLoggedIn } from "../services/auth"
import Login from './login'

import pic01 from '../assets/images/instructions-mighties-01.png'
import pic02 from '../assets/images/instructions-mighties-02.png'
import pic03 from '../assets/images/instructions-mighties-03.png'

const Mighties = (props) => {
    if (!isLoggedIn()) {
        return <Login />
    }
    return (
        <Layout>
            <Helmet>
                <title>Citrus - Moonlight</title>
                <meta name="description" content="Pomegranates" />
            </Helmet>

            <BannerLanding
                bannerStyle="style6"
                headerImage={pic01}
            />

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>Mighty In Every Sense</h2>
                        </header>
                        <p>Moonlight is pleased to announce a new partnership with a truly mighty snack fruit, Mighties™! Mighties are mighty in every sense, but they’re also America’s best kept secret, until now. As we unveil this surprising little superfood to the world, we will be launching more than just a kiwi – we’ll be inspiring parents and kids alike to Be Mightie. Mighties may be small, but Be Mightie is the start of something big. The world is just a slice and a few scoops away from being MIGHTIE…now from Moonlight.</p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <div className="image">
                            <img src={pic02} alt="" />
                            <img src={pic03} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>KIWI STORAGE AND HANDLING TIPS</h3>
                                </header>
                                <ul>
                                    <li>
                                        <p>Mighties™ kiwifruit may be a lot of things — fuzzy, funny-looking, delicious, delightful — but “delicate” they’re not. They’ll keep for several days at room temperature and up to four weeks in your refrigerator. (They can even be held in commercial storage for over 10 months … but don’t try that at home!)</p>
                                    </li>
                                    <li>
                                        <p>If your kiwifruit’s a little firm and you’re getting anxious to eat it, try this: Place it in a vented plastic bag with an apple or a banana and leave it out on the counter for a day or two. It should ripen right up!</p>
                                    </li>
                                    <li>
                                        <p>Press the outside of the fruit with your thumb. If it gives to slight pressure, the kiwifruit is ripe. If it doesn’t give to pressure, it’s not ready to eat. Simple as that.</p>
                                    </li>
                                </ul>
                                <h5>Enjoying your kiwifruit is Mightie easy.</h5>
                                <p>
                                    There’s no “right” or “wrong” way to eat Mighties™ kiwifruit. Most people find that slicing and scooping is a good way to get the most from their kiwifruit. Using a sharp knife, slice the kiwifruit lengthwise to create two identical halves. Then use a spoon to scoop the sweet, delicious meat of the kiwifruit from each half. Looking for maximum fiber and nutrition? Don’t throw that skin away! It’s loaded with nutrients and fiber, so rinse it off and bite right in!
                            </p>
                                <h5>Did you know you eat the skin of a kiwifruit?</h5>
                                <p>
                                    Absolutely! The kiwifruit skin is completely edible and makes this nutrient-dense fruit even more nutritious! A recent study shows that eating the skin triples the fiber intake compared to merely eating the flesh. And by not peeling the skin, you preserve much of the vitamin C content as well.  People all over the world have been eating the skin for centuries with no complaints. And many find that leaving the skin on their sliced kiwifruit makes it much easier for snacking, as the skin holds each slice together.
                            </p>
                                <p>
                                    Just like any fresh fruit or vegetable, we suggest that you wash the skin before eating. So enjoy your Mighties™  kiwifruit, skin and all!
                            </p>
                                <ul className="actions">
                                    <li><Link to="/#mighties" className="button">Back</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

        </Layout>
    )
}

export default Mighties